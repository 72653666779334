/*     General overwrite     */
body,
.wrapper {
    min-height: 100vh;
    position: relative;
}
a {
    color: $info-color;

    &:hover,
    &:focus {
        color: $info-states-color;
        text-decoration: none;
    }
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline: 0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
    outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
    @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
    @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
    width: 18px;
    text-align: center;
}
.margin-top {
    margin-top: 50px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;
}

// documentation

.page-header {
    .page-header-image {
        background-position: center center;
        background-size: cover;
        overflow: hidden;
        width: 100%;
        z-index: 1;
    }
    .title-container {
        color: #fff;
        position: relative;
        top: 250px;
        z-index: 3;
    }
    .filter:after {
        background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #9368e9 0%, #943bea 100%) repeat scroll 0 0 / 150% 150%;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.77;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
}

.documentation .page-header,
.documentation .page-header-image,
.documentation .page-header-image .filter:after {
    height: 100vh;
}

.documentation .footer {
    z-index: 3;
}
.documentation .wrapper {
    margin-top: -61px;
    height: 100vh;
}
.documentation .navbar {
    z-index: 21;
}
